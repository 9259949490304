import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit';
import { message } from 'antd';
import { ErrorIcon } from '../../assets/icons/ErrorIcon';
import { SuccessIcon } from '../../assets/icons/SuccessIcon';
import { ProfileService } from '../../services/profileService';
import { IInitialState } from '../../types/entities/entitites';
import { IConfirmCodeEmail, IRegistrationData, ISetPersAgrmnt, IToken } from '../../types/interfaces/interfaces';
import { errorsKey } from '../../utils';
import {
  changeEmail,
  confirmEmail,
  confirmPhone,
  errors,
  Is_Load_But,
  IsLoading,
  ModalVisible,
  ModalVisibleForEmail,
  ModifiedEmail,
  needFields,
  progressCountStore,
  progressStore,
  Token,
  User,
  userAddress,
  userPhoto,
  ModalVisibleForChangeEmail,
  userInn,
  ModalVisibleForPhone,
  changePhone,
} from '../profileSlice';

interface IUser {
  profileSlice: {
    values: IInitialState
    _params_send: string,
    _url: {
      redirect: string
    },
    _token: string,
  }

}

// проверка сложности пароля
export const checkPassword = (value: string) => (dispatch: Dispatch<any>) => {
  const password = value; // Получаем пароль из формы

  const is_s:any = /[a-z]/.test(password); // Есть ли в пароле буквы в нижнем регистре
  const is_b = /[A-Z]/.test(password); // Есть ли в пароле буквы в верхнем регистре
  const is_d = /[0-9]/.test(password); // Есть ли в пароле цифры
  const is_sp = /[^\w]/.test(password); // Есть ли в пароле спецсимволы

  const rating = is_s + is_b + is_d + is_sp; // Рейтинг уже просуммирован
  let text;
  let count;

  /* Далее идёт анализ длины пароля и полученного рейтинга, и на основании этого готовится текстовое описание сложности пароля */

  if (!password.length) {
    count = 0;
  }
  else if (password.length < 6) {
    count = rating >= 3 ? 50 : 20;
  }
  else if (rating === 4) {
    count = 100;
  }
  else if (rating === 3) {
    count = 85;
  }
  else if (rating > 1) {
    count = 70;
  }
  else {
    count = 30;
  }

  // eslint-disable-next-line prefer-const
  text
    = count === 0
      ? 'Нет пароля'
      : count >= 20 && count < 50
        ? 'низкая'
        : count >= 50 && count < 75
          ? 'средняя'
          : 'высокая';
  dispatch(progressStore(text));
  dispatch(progressCountStore(count));
  return true; // Форму не отправляем
};

// Валидация снилса
export const validateSnils = createAsyncThunk(
  'profileSlice/validateSnils',
  async(value: string, { dispatch }) => {
    let snils: any = value.replace(/[^+\d]/g, '');
    if (typeof snils === 'number') {
      snils = snils.toString();
    }
    else if (typeof snils !== 'string') {
      snils = '';
    }

    if (!snils.length) {
      dispatch(errors('СНИЛС пуст'));
    }
    else if (/[^0-9]/.test(snils)) {
      dispatch(errors('СНИЛС может состоять только из цифр'));
    }
    else if (snils.length !== 11) {
      dispatch(errors('СНИЛС может состоять только из 11 цифр'));
    }
    else if (snils === '00000000000') {
      dispatch(errors('СНИЛС не может состоять из одних нулей'));
    }
    else {
      let sum = 0;
      let checkDigit = 0;

      for (let i = 0; i < 9; i += 1) {
        sum += parseInt(snils[i]) * (9 - i);
      }

      if (sum < 100) {
        checkDigit = sum;
      }
      else if (sum > 101) {
        checkDigit = parseInt(String(sum % 101));
        if (checkDigit === 100) {
          checkDigit = 0;
        }
      }

      if (checkDigit === parseInt(snils.slice(-2))) {
        dispatch(errors(''));
      }
      else {
        dispatch(errors('Неправильное контрольное число'));
      }
    }
  },
);

// регистрация
export const registration = createAsyncThunk(
  'profileSlice/registration',
  async({ data, password, isRegistration }: IRegistrationData, { dispatch, getState }) => {
    const response = await ProfileService.$set_User(data);
    if (response?.data.status) {
      message.success({
        className: 'messageSuccess',
        content: 'Вы зарегистрировались',
        icon: <SuccessIcon />,
      });
      const { profileSlice } = getState() as IUser;
      const token = profileSlice._token;
      await dispatch(setPersAgrmnt({ token, agree: true }));
      password && dispatch(update_password({ token, password, isRegistration }));
      let url = profileSlice._url.redirect;
      if (url) {
        url = decodeURIComponent(url);
        setTimeout(() => (window.location.href = url), 1000);
      }
      else {
        await dispatch(get_users(token));
      }
    }
    else {
      const err = response?.data.errors[0].key;
      const show = errorsKey(err);
      message.error({
        className: 'messageError',
        content: show,
        icon: <ErrorIcon />,
      });
      dispatch(IsLoading(false));
    }
  },
);

// запрос кода смс
export const requestSMS = createAsyncThunk(
  'profileSlice/requestSMS',
  async({ phone, token, modal }: IToken, { dispatch }) => {
    const value = JSON.stringify({ phone, token, p: false });
    dispatch(Is_Load_But(true));
    const response = await ProfileService.$change_phone(value);
    if (response?.data.status) {
      if (response.data.values[0] === 'foreign') {
        message.success({
          className: 'messageSuccess',
          content: 'Номер подтвержден',
          icon: <SuccessIcon />,
        });
        if (phone) {
          dispatch(changePhone(phone));
        }
      }
      else {
        message.success({
          className: 'messageSuccess',
          content: 'Код успешно выслан',
          icon: <SuccessIcon />,
        });
        if (modal) {
          dispatch(ModalVisible(true));
        }
      }
    }
    else {
      const err = response?.data.errors[0].key;
      const show = errorsKey(err);
      message.error({
        className: 'messageError',
        content: show,
        icon: <ErrorIcon />,
      });
    }
    dispatch(Is_Load_But(false));
  },
);

// Подтверждение номера телефона в профиле
export const confirmPhoneProfile = createAsyncThunk(
  'profileSlice/confirmPhoneProfile',
  async({ token, code, phone }: IToken, { dispatch }) => {
    const value = JSON.stringify({ token, code });
    const response = await ProfileService.$confirmPhoneProfile(value);
    if (response?.data.status) {
      message.success({
        className: 'messageSuccess',
        content: 'Телефон подтвержден',
        icon: <SuccessIcon />,
      });
      dispatch(ModalVisibleForPhone(false));
      dispatch(confirmPhone(true));
      if (phone) {
        dispatch(changePhone(phone));
      }
    }
    else {
      const err = response?.data.errors[0].key;
      const show = errorsKey(err);
      message.error({
        className: 'messageError',
        content: show,
        icon: <ErrorIcon />,
      });
    }
  },
);

// Изменение номера телефона в профиле
export const changePhoneProfile = createAsyncThunk(
  'profileSlice/_confirm_code',
  async({ code, token, phone }: IToken, { dispatch }) => {
    const value = JSON.stringify({ code, token });
    const response = await ProfileService.$confirmPhoneProfile(value);
    if (response?.data.status) {
      message.success({
        className: 'messageSuccessEdit',
        content: 'Номер успешно изменен',
        icon: (
          <div className="iconSuccess">
            <SuccessIcon />
          </div>
        ),
      });
      dispatch(ModalVisible(false));
      if (token) {
        dispatch(Token(token));
      }
      if (phone) {
        dispatch(changePhone(phone));
      }
    }
    else {
      const err = response?.data.errors[0].key;
      const show = errorsKey(err);
      message.error({
        className: 'messageError',
        content: show,
        icon: <ErrorIcon />,
      });
    }
  },
);

// Обновление пароля
export const update_password = createAsyncThunk(
  'profileSlice/update_password',
  async({ token, password, isRegistration }: IRegistrationData, { dispatch, getState }) => {
    const value = JSON.stringify({ token, password });
    const { profileSlice } = getState() as IUser;
    const response = await ProfileService.$up_password(value);
    if (response?.data.status) {
      dispatch(IsLoading(true));
      message.success({
        className: 'messageSuccessEdit',
        content: 'Пароль успешно изменен',
        icon: (
          <div className="iconSuccess">
            <SuccessIcon />
          </div>
        ),
      });
      if (!isRegistration) {
        let url = profileSlice._url.redirect;
        if (url) {
          url = decodeURIComponent(url);
        }
        setTimeout(() => window.location.href = url || process.env.REACT_APP_UNIONE_BASE_URL || '', 3000);
      }
    }
    else {
      const err = response?.data.errors[0]?.key;
      const show = errorsKey(err);
      const contentError = err ? show : (
        <div className="errorMessage">
          <h4 className="errorMessageTitle">Что-то пошло не так</h4>
          <p className="errorMessageText">
            При смене пароля произошла ошибка, пожалуйста, попробуйте еще раз
          </p>
        </div>
      );
      message.error({
        className: 'messageErrorEdit',
        content: contentError,
        icon: (
          <div className="iconError">
            <ErrorIcon />
          </div>
        ),
      });
    }
  },
);

// получение данных через токен
export const get_users = createAsyncThunk(
  'profileSlice/get_users',
  async(data: string, { dispatch }) => {
    const value = JSON.stringify({ token: data });
    const response = await ProfileService.$get_User(value);
    if (response?.data.status) {
      const need_fields = response?.data.need_fields;
      dispatch(needFields(need_fields));
      const user = response?.data.values[0];
      dispatch(User(user));
      dispatch(confirmEmail(user?.confirm.email));
      dispatch(confirmPhone(user?.confirm.phone));
    }
    else {
      const err = response?.data.errors[0].key;
      const show = errorsKey(err);
      message.error({
        className: 'messageError',
        content: show,
        icon: <ErrorIcon />,
      });
      if (err === 105 || 123) {
        console.log('error 105 and 123');
        return (window.location.href = process.env.REACT_APP_AUTH_BASE_URL || '');
      }
    }
    dispatch(IsLoading(false));
  },
);

// изменение данных
export const set_users = createAsyncThunk(
  'profileSlice/set_users',
  async(data: string, { dispatch, getState }) => {
    const response = await ProfileService.$set_User(data);
    if (response?.data.status) {
      dispatch(IsLoading(true));
      message.success({
        className: 'messageSuccess',
        content: 'Вы успешно изменили данные',
        icon: <SuccessIcon />,
      });
      const { profileSlice } = getState() as IUser;
      let url = profileSlice._url.redirect;
      if (url) {
        url = decodeURIComponent(url);
      }
      setTimeout(() => (window.location.href
        = url || process.env.REACT_APP_UNIONE_BASE_URL || ''), 1000);
    }
    else {
      const err = response?.data.errors[0].key;
      const show = errorsKey(err);
      message.error({
        className: 'messageError',
        content: show,
        icon: <ErrorIcon />,
      });
      dispatch(IsLoading(false));
    }
  },
);

// загрузка изображений
export const upload_file = createAsyncThunk(
  'profileSlice/upload_file',
  async({ token, file }: { token: string, file: string }, { dispatch }) => {
    const blob = await fetch(file).then((f) => f.blob());
    const data = new FormData();
    data.append('token', token);
    data.append('file', blob);
    const response = await ProfileService.$upload_File(data);
    if (response?.data.status) {
      const photo = response?.data.values[0];
      dispatch(userPhoto(String(photo)));
      message.success({
        className: 'messageSuccess',
        content: 'Вы успешно добавили фото профиля',
        icon: <SuccessIcon />,
      });
    }
    else {
      const err = response?.data.errors[0].key;
      const show = errorsKey(err);
      message.error({
        className: 'messageError',
        content: show,
        icon: <ErrorIcon />,
      });
    }
  },
);

// @-Changed Отправка DaData
export const getUserGeoData = createAsyncThunk(
  'profileSlice/getUserGeoData',
  async({ token, query }: IToken, { dispatch }) => {
    const value = JSON.stringify({ token, query });
    const response = await ProfileService.$getUserGeoData(value);
    if (response?.data.status) {
      const res = response?.data.value;
      dispatch(userAddress(res));
    }
    else {
      const err = response?.data.error;
      message.error({
        className: 'messageError',
        content: err,
        icon: <ErrorIcon />,
      });
    }
  },
);

export const confirm_code_email = createAsyncThunk(
  'profileSlice/confirm_code_email',
  async({ token, code, modifiedEmail, emailConfirm }: IConfirmCodeEmail, { dispatch }) => {
    const value = JSON.stringify({ token, code });
    dispatch(Is_Load_But(true));
    const response = await ProfileService.$confirmCodeEmail(value);
    if (response?.data.status) {
      message.success({
        className: 'messageSuccessEdit',
        content: `Адрес электронной почты успешно ${emailConfirm ? 'изменен' : 'подтвержден '}`,
        icon: (
          <div className="iconSuccess">
            <SuccessIcon />
          </div>
        ),
      });
      dispatch(confirmEmail(true));
      dispatch(ModalVisibleForEmail(false));
      dispatch(changeEmail(modifiedEmail));
    }
    else {
      const err = response?.data.errors[0].key;
      const show = errorsKey(err);
      message.error({
        className: 'messageError',
        content: show,
        icon: <ErrorIcon />,
      });
    }
    dispatch(Is_Load_But(false));
  },
);


export const requestSMSforEmail = createAsyncThunk(
  'profileSlice/requesSMSforEmail',
  async({ token, email }: IToken, { dispatch }) => {
    const value = JSON.stringify({ token, email });
    const response = await ProfileService.$getting_key_for_email(value);
    if (response?.data.status) {
      message.success({
        className: 'messageSuccess',
        content: 'Введите код доступа!',
        icon: <SuccessIcon />,
      });
      if (email) {
        dispatch(ModifiedEmail(email));
      }
      dispatch(ModalVisibleForChangeEmail(false));
      dispatch(ModalVisibleForEmail(true));
    }
    else {
      const err = response?.data.errors[0].key;
      const show = errorsKey(err);
      message.error({
        className: 'messageError',
        content: show,
        icon: <ErrorIcon />,
      });
    }
  },
);

// Отправка DaDataInn
export const getUserInnData = createAsyncThunk(
  'profileSlice/getUserInnData',
  async({ token, query }: IToken, { dispatch }) => {
    const value = JSON.stringify({ token, query });
    const response = await ProfileService.$getUserInnData(value);
    if (response?.data.status) {
      const res = response?.data.value;
      dispatch(userInn(res));
    }
    else {
      const err = response?.data.error;
      message.error({
        className: 'messageError',
        content: err,
        icon: <ErrorIcon />,
      });
    }
  },
);

// Отправка смс с кодом подтверждения на номер телефона пользователя
export const sendSmsCode = createAsyncThunk(
  'profileSlice/sendSmsCode',
  async({ _token, phoneValue }: { _token: string; phoneValue: string }, { dispatch }) => {
    const value = JSON.stringify({ token: _token, phone: phoneValue });
    const response = await ProfileService.$sendSmsCode(value);
    if (response?.data.status) {
      if (response.data.values[0] === 'foreign') {
        message.success({
          className: 'messageSuccess',
          content: 'Номер подтвержден',
          icon: <SuccessIcon />,
        });
        dispatch(changePhone(phoneValue));
        dispatch(confirmPhone(true));
      }
      else {
        message.success({
          className: 'messageSuccess',
          content: 'код успешно выслан!',
          icon: <SuccessIcon />,
        });
        dispatch(ModalVisibleForPhone(true));
      }
    }
    else {
      const err = response?.data.errors[0].key;
      const show = errorsKey(err);
      message.error({
        className: 'messageError',
        content: show,
        icon: <ErrorIcon />,
      });
    }
  },
);

// Сохранение публичной информации о пользователе
export const setPersAgrmnt = createAsyncThunk(
  'profileSlice/getUserInnData',
  async({ token, agree }: ISetPersAgrmnt, { dispatch }) => {
    const value = JSON.stringify({ token, agree });
    const response = await ProfileService.$setPersAgrmnt(value);
    if (response?.data.status) {
      message.success({
        className: 'messageSuccess',
        content: 'Данные успешно подтверждены!',
        icon: <SuccessIcon />,
      });
    }
    else {
      const err = response?.data.errors[0].key;
      message.error({
        className: 'messageError',
        content: err,
        icon: <ErrorIcon />,
      });
    }
  },
);
