import { ReactNode } from 'react';
import { Select } from 'antd';
import { arm, blr, chn, kaz, kgz, mng, rus, uzb } from '../assets/flags';
import { IEndpoints } from '../types/interfaces/interfaces';

export const CountryCodes = {
  RF: '7',
  KZ: '7',
  BY: '375',
  KG: '996',
  UZ: '998',
  AM: '374',
  MN: '976',
  CN: '86',
};

export const getMask = (code: string) => {
  switch (code) {
    case CountryCodes.RF:
      return '(000)000-00-00';
    case CountryCodes.KG:
      return '(000)00-00-00';
    case CountryCodes.KZ:
      return '(000)000-00-00';
    case CountryCodes.BY:
      return '(00)000-00-00';
    case CountryCodes.UZ:
      return '(00)000-00-00';
    case CountryCodes.AM:
      return '(000)000-00-00';
    case CountryCodes.MN:
      return '(000)000-0000';
    case CountryCodes.CN:
      return '(000)0000-0000';
    default:
      return '(000)000-00-00';
  }
};

export const selectOptions: ReactNode = (
  <>
    <Select.Option value='{"code": "+7", "acronym": "RF"}'><img src={rus} alt="" width={20} /> +7</Select.Option>
    <Select.Option value='{"code": "+7", "acronym": "KZ"}'><img src={kaz} alt="" width={20} /> +7</Select.Option>
    <Select.Option value='{"code": "+375", "acronym": "BY"}'><img src={blr} alt="" width={20} /> +375</Select.Option>
    <Select.Option value='{"code": "+996", "acronym": "KG"}'><img src={kgz} alt="" width={20} /> +996</Select.Option>
    <Select.Option value='{"code": "+998", "acronym": "UZ"}'><img src={uzb} alt="" width={20} /> +998</Select.Option>
    <Select.Option value='{"code": "+374", "acronym": "AM"}'><img src={arm} alt="" width={20} /> +374</Select.Option>
    <Select.Option value='{"code": "+976", "acronym": "MN"}'><img src={mng} alt="" width={20} /> +976</Select.Option>
    <Select.Option value='{"code": "+86", "acronym": "CN"}'><img src={chn} alt="" width={20} /> +86</Select.Option>
  </>
);

export const passwordCondition = 'Пароль может содержать цифры, символы и латинские буквы.';
export const cancel = 'Отмена';
export const save = 'Сохранить';
export const next = 'Далее';
export const unbind = 'Отвязать';

export const base_url = process.env.REACT_APP_AUTH_BASE_URL;
export const endpoints:IEndpoints = {
  sing_in: process.env.REACT_APP_AUTH_SIGN_IN,
  get_code: process.env.REACT_APP_AUTH_GET_CODE,
  get_user: process.env.REACT_APP_AUTH_GET_USER_DATA,
  set_user: process.env.REACT_APP_AUTH_SET_USER_DATA,
  register: process.env.REACT_APP_AUTH_REGISTRATION,
  update_password: process.env.REACT_APP_AUTH_UPDATE_PASSWORD,
  upload_file: process.env.REACT_APP_AUTH_UPLOAD_FILE,
  change_phone: process.env.REACT_APP_AUTH_CHANGE_PHONE,
  query_da_data: process.env.REACT_APP_AUTH_POST_DA_DATA_QUERY,
  confirm_code_email: process.env.REACT_APP_AUTH_CONFIRM_CODE_FOR_EMAIL,
  get_code_for_email: process.env.REACT_APP_AUTH_GET_CODE_FOR_EMAIL,
  query_da_data_inn: process.env.REACT_APP_AUTH_POST_DA_DATA_QUERY_INN,
  upload_doc: process.env.REACT_APP_AUTH_UPLOAD_DOC,
  delete_doc: process.env.REACT_APP_AUTH_DELETE_DOC,
  edit_doc: process.env.REACT_APP_AUTH_EDIT_DOC,
  send_sms_code: process.env.REACT_APP_AUTH_SEND_SMS_CODE,
  confirm_phone_profile: process.env.REACT_APP_AUTH_CONFIRM_PHONE_PROFILE,
  send_email_code: process.env.REACT_APP_AUTH_SEND_EMAIL_CODE,
  bind_leader_id: process.env.REACT_APP_AUTH_BIND_LEADER_ID,
  callback_leader_id: process.env.REACT_APP_AUTH_CALLBACK_LEADER_ID,
  disconnect_with_leader_id: process.env.REACT_APP_AUTH_DISCONNECT_WITH_LEADER_ID,
  telegram_bind: process.env.REACT_APP_AUTH_BIND_TELEGRAM,
  confirm_telegram_profile: process.env.REACT_APP_CONFIRM_TELEGRAM_PROFILE,
  esia_bind: process.env.REACT_APP_AUTH_ESIA_BIND,
  esia_unbind: process.env.REACT_APP_AUTH_ESIA_UNBIND,
  get_esia_login_link: process.env.REACT_APP_AUTH_GET_ESIA_LOGIN_LINK,
  set_pers_agrmnt: process.env.REACT_APP_AUTH_SET_PERS_AGRMNT,
};

export const base_image_url = process.env.REACT_APP_UNIONE_BASE_IMAGE_URL;

export const military_university_code = process.env.REACT_APP_CHECK_STUDENT_OF_MILITARY_UNIVERSITY;

export const base_da_data_url = process.env.REACT_APP_AUTH_POST_DA_DATA;

export const BASE_BUCKET = 'basebucket';
export const ASS_BASE_BUCKET = 'assessment';

export const imgConfigurator = `${base_image_url}/${BASE_BUCKET}/`;
export const proctoImgConfigurator = `${base_image_url}/${ASS_BASE_BUCKET}/`;


export const PinInputStyle = {
  background: '#FAFAFF',
  border: '1px solid #E4E7F2',
  borderRadius: '16px',
  fontSize: 20,
  color: '#3B4168',
  height: 48,
  width: 48,
  margin: '0 0.75rem',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 600,
};

export const PinContainerStyle = {
  marginBottom: '2.5rem',
  display: 'flex',
  justifyContent: 'center',
};

export const pastelBlue = '#99C6EB';
export const brightRed = '#EB5757';
export const greenRgbColor = 'rgb(25,135,84)';
