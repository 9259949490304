import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AcronymEnum, IDocImage, IInitialState, IRedirect } from '../types/entities/entitites';
import { IGeoValue, IUsersData } from '../types/responses/responses';

const initialState: IInitialState = {
  values: {
    id: '',
    email: '',
    phone: '',
    login: '',
    status: '',
    avatar: '',
    sex: '',
    has_pass: false,
    is_leader_id: false,
    is_esia: false,
    moderation: {
      is_moderated: false,
    },
    personal_agreement: {
      agree: false,
      updated_at: '',
    },
    confirm: {
      email: false,
      phone: false,
      telegram: false,
    },
    user_info: {
      geo: {
        country: '',
        city: '',
        fias_addr: '',
        fias_id: '',
      },
      work: {
        place: '',
        industry: '',
        position: '',
        inn: '',
      },
      birthday: {
        year: null,
        month: null,
        day: null,
      },
      passport: {
        name: '',
        sur_name: '',
        patronymic: '',
      },
      docs: {
        id_doc: '',
        id_doc_name: '',
        images: {
          passport: null,
          diploma: null,
          proctoring: null,
          qualification: null,
        },
        snils: '',
        snils_dop: '',
        msg_proct: '',
      },
    },
  },
  errors: '',
  _token: '',
  s3_token: '',
  _IsLoading: true,
  _is_modal_visible: false,
  _isLoadButton: false,
  _IsLoading_Admin: false,
  _url: {
    redirect: null,
  },
  _progress: '',
  _progressCount: '',
  address: [],
  _is_modal_visible_email: false,
  _is_modal_visible_change_email: false,
  _is_modal_visible_phone: false,
  _is_confirm_telegram_modal_visible: false,
  _is_bind_telegram_modal_visible: false,
  diploma: [],
  passport: [],
  qualification: [],
  proctoring: [{ type: null }, { type: null }, { type: null }],
  modifiedPhone: '',
  modifiedEmail: '',
  result_valid_inn: true,
  errors_inn: '',
  inn: [],
  need_fields: null,
  emailConfirm: false,
  phoneConfirm: false,
  acronym: AcronymEnum.rf,
};

export const profileSlice = createSlice({
  name: 'adminSlice',
  initialState,
  reducers: {
    Qualification(state, action: PayloadAction<IDocImage[]>) {
      state.qualification = action.payload;
    },
    Passport(state, action: PayloadAction<IDocImage[]>) {
      state.passport = action.payload;
    },
    Diploma(state, action: PayloadAction<IDocImage[]>) {
      state.diploma = action.payload;
    },
    Proctoring(state, action: PayloadAction<IDocImage[]>) {
      const items = action.payload.slice(0, 3);
      const placeholders: IDocImage[] = state.proctoring.slice(items.length);
      state.proctoring = [...items, ...placeholders];
    },
    ProctoringDelete(state, action: PayloadAction<any>) {
      state.proctoring = action.payload;
    },
    errors(state, action: PayloadAction<string>) {
      state.errors = action.payload;
    },
    User(state, action: PayloadAction<IUsersData>) {
      state.values = action.payload;
    },
    progressStore(state, action: PayloadAction<string>) {
      state._progress = action.payload;
    },
    progressCountStore(state, action: PayloadAction<number>) {
      state._progressCount = action.payload;
    },
    ModalVisible(state, action: PayloadAction<boolean>) {
      state._is_modal_visible = action.payload;
    },
    IsLoading(state, action: PayloadAction<boolean>) {
      state._IsLoading = action.payload;
    },
    Url_Put(state, action: PayloadAction<IRedirect>) {
      state._url = action.payload;
    },
    Is_Load_But(state, action: PayloadAction<boolean>) {
      state._isLoadButton = action.payload;
    },
    Token(state, action: PayloadAction<string>) {
      state._token = action.payload;
    },
    userAddress(state, action: PayloadAction<IGeoValue[]>) {
      state.address = action.payload;
    },
    userPhoto(state, action: PayloadAction<string>) {
      state.values.avatar = action.payload;
    },
    ModalVisibleForEmail(state, action: PayloadAction<boolean>) {
      state._is_modal_visible_email = action.payload;
    },
    ModalVisibleForChangeEmail(state, action: PayloadAction<boolean>) {
      state._is_modal_visible_change_email = action.payload;
    },
    ModalVisibleForPhone(state, action: PayloadAction<boolean>) {
      state._is_modal_visible_phone = action.payload;
    },
    ConfirmTelegramModalVisible(state, action: PayloadAction<boolean>) {
      state._is_confirm_telegram_modal_visible = action.payload;
    },
    BindTelegramModalVisible(state, action: PayloadAction<boolean>) {
      state._is_bind_telegram_modal_visible = action.payload;
    },
    ModifiedPhone(state, action: PayloadAction<string>) {
      state.modifiedPhone = action.payload;
    },
    ModifiedEmail(state, action: PayloadAction<string>) {
      state.modifiedEmail = action.payload;
    },
    ErrorsInn(state, action: PayloadAction<string>) {
      state.errors_inn = action.payload;
    },
    ResultValidateInn(state, action: PayloadAction<boolean>) {
      state.result_valid_inn = action.payload;
    },
    userInn(state, action: PayloadAction<any>) {
      state.inn = action.payload;
    },
    needFields(state, action: PayloadAction<boolean>) {
      state.need_fields = action.payload;
    },
    confirmEmail(state, action: PayloadAction<boolean>) {
      state.emailConfirm = action.payload;
    },
    confirmPhone(state, action: PayloadAction<boolean>) {
      state.phoneConfirm = action.payload;
    },
    changePhone(state, action: PayloadAction<string>) {
      state.values.phone = action.payload;
    },
    changeEmail(state, action: PayloadAction<string>) {
      state.values.email = action.payload;
    },
    changeAcronym(state, action: PayloadAction<AcronymEnum>) {
      state.acronym = action.payload;
    },
    changeLeaderIdStatus(state, action: PayloadAction<boolean>) {
      state.values.is_leader_id = action.payload;
    },
    changeTelegramBind(state, action: PayloadAction<boolean>) {
      state.values.confirm.telegram = action.payload;
    },
    changeEsiaStatus(state, action: PayloadAction<boolean>) {
      state.values.is_esia = action.payload;
    },
    s3Token(state, action: PayloadAction<string>) {
      state.s3_token = action.payload;
    },
  },
});

export const {
  Qualification,
  Passport,
  Diploma,
  Proctoring,
  errors,
  User,
  progressStore,
  progressCountStore,
  ModalVisible,
  IsLoading,
  Url_Put,
  Is_Load_But,
  Token,
  userAddress,
  userPhoto,
  ModalVisibleForEmail,
  ModalVisibleForChangeEmail,
  ModalVisibleForPhone,
  ModifiedPhone,
  ModifiedEmail,
  ErrorsInn,
  ResultValidateInn,
  userInn,
  needFields,
  confirmEmail,
  confirmPhone,
  changePhone,
  changeEmail,
  ProctoringDelete,
  changeAcronym,
  changeLeaderIdStatus,
  ConfirmTelegramModalVisible,
  BindTelegramModalVisible,
  changeTelegramBind,
  changeEsiaStatus,
  s3Token,
} = profileSlice.actions;

export default profileSlice.reducer;
