import React, { FC, useState } from 'react';
import { Button, Checkbox, Col, Modal, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { setPersAgrmnt } from '../../../store/actions/profileAction';
import { useAppDispatch } from '../../../store/store';
import { IConfirmData } from '../../../types/interfaces/interfaces';
import { phoneMask } from '../../../utils/PhoneFilter';
import styles from './ConfirmData.module.css';

export const ConfirmData: FC<IConfirmData> = ({
  values,
  confirmDataVisible,
  setConfirmDataVisible,
  token,
}) => {
  const dispatch = useAppDispatch();
  const [confirmDataDisabled, setConfirmDataDisabled] = useState<boolean>(true);
  const patronymic = values.user_info.passport.patronymic || '';
  const userFullName = `${values.user_info.passport.sur_name} ${values.user_info.passport.name} ${patronymic}`;
  const userPhone = phoneMask(values.phone);

  const onConfirmData = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setConfirmDataDisabled(false);
    }
    else {
      setConfirmDataDisabled(true);
    }
  };

  const sendConfirmData = () => {
    dispatch(setPersAgrmnt({ token, agree: true }));
    setConfirmDataVisible(false);
  };

  return (
    <div>
      <Modal
        className={styles.modal}
        open={confirmDataVisible}
        width={796}
        bodyStyle={{
          paddingTop: 32,
          paddingRight: 32,
          paddingBottom: 0,
          paddingLeft: 32,
        }}
        onCancel={() => setConfirmDataVisible(false)}
        footer={[
          <Button
            type="default"
            key="back"
            onClick={() => setConfirmDataVisible(false)}
          >
            Изменить данные
          </Button>,
          <Button
            type="primary"
            key="submit"
            disabled={confirmDataDisabled}
            onClick={sendConfirmData}
          >
            Подтвердить данные
          </Button>,
        ]}
      >
        <div className={styles.body}>
          <h2 className={styles.title}>Подтвердить данные</h2>
          <div className={styles.line} />
          <h4 className={styles.minorTitle}>
            Подтвердите правильность указанных ниже данных
          </h4>
          <p className={styles.description}>
            На основании этих данных будут сформированы документы по итогам обучения или прохождения оценки компетенций
          </p>
          <div className={styles.userInfoBlock}>
            <Row gutter={[32, 24]}>
              <Col sm={24} md={12}>
                <div className={styles.userInfo}>
                  <p className={styles.userInfoLabel}>ФИО</p>
                  <p className={styles.userInfoValue}>{userFullName}</p>
                </div>
              </Col>
              <Col sm={24} md={12}>
                {values.user_info.docs.snils
                  ? (
                    <div className={styles.userInfo}>
                      <p className={styles.userInfoLabel}>СНИЛС</p>
                      <p className={styles.userInfoValue}>{values.user_info.docs.snils}</p>
                    </div>
                  )
                  : (
                    <div className={styles.userInfo}>
                      <p className={styles.userInfoLabel}>{values.user_info.docs.id_doc_name}</p>
                      <p className={styles.userInfoValue}>{values.user_info.docs.id_doc}</p>
                    </div>
                  )}
              </Col>
              <Col sm={24} md={12}>
                <div className={styles.userInfo}>
                  <p className={styles.userInfoLabel}>Телефон</p>
                  <p className={styles.userInfoValue}>{userPhone}</p>
                </div>
              </Col>
              <Col sm={24} md={12}>
                <div className={styles.userInfo}>
                  <p className={styles.userInfoLabel}>e-mail</p>
                  <p className={styles.userInfoValue}>{values.email}</p>
                </div>
              </Col>
              <Col span={24}>
                <div className={styles.userInfo}>
                  <p className={styles.userInfoLabel}>Адрес</p>
                  <p className={styles.userInfoValue}>{values.user_info.geo.fias_addr}</p>
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col span={24}>
              <Checkbox
                className={styles.checkbox}
                onChange={onConfirmData}
              >
                Соглашаюсь с тем, что предоставленные данные являются верными и информирован,
                что на основании этих данных будут сформированы документы по итогам обучения
                или прохождения оценки компетенций
              </Checkbox>
            </Col>
          </Row>
          <div className={styles.line} />
        </div>
      </Modal>
    </div>
  );
};
