import axios, { AxiosResponse } from 'axios';
import { IPostResponse, IUserGeoData, IUsersData } from '../types/responses/responses';
import { base_da_data_url, base_url, endpoints } from '../utils/const';

export class ProfileService {

  static async $edit_doc(data: FormData) {
    if (base_url && endpoints.edit_doc) {
      return axios.post(base_url + endpoints.edit_doc, data);
    }
  }

  static async $delete_doc(data: FormData) {
    if (base_url && endpoints.delete_doc) {
      return axios.post(base_url + endpoints.delete_doc, data);
    }
  }

  static async $upload_doc(data: FormData) {
    if (base_url && endpoints.upload_doc) {
      return axios.post(base_url + endpoints.upload_doc, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    }
  }

  static async $get_User(data: string) {
    if (base_url && endpoints.get_user) {
      return axios.post<IPostResponse<IUsersData>>(base_url + endpoints.get_user, data);
    }
  }

  static async $set_User(data: string | undefined) {
    if (base_url && endpoints.set_user) {
      return axios.post<IPostResponse>(base_url + endpoints.set_user, data);
    }
  }

  static async $up_password(data: string) {
    if (base_url && endpoints.update_password) {
      return axios.post<IPostResponse>(base_url + endpoints.update_password, data);
    }
  }

  static async $upload_File(data: FormData) {
    if (base_url && endpoints.upload_file) {
      return axios.post<IPostResponse>(base_url + endpoints.upload_file, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    }
  }

  static async $change_phone(data: string) {
    if (base_url && endpoints.change_phone) {
      return axios.post(base_url + endpoints.change_phone, data);
    }
  }

  static async $getUserGeoData(data: string) {
    if (base_da_data_url && endpoints.query_da_data) {
      return axios.post<IUserGeoData>(base_da_data_url + endpoints.query_da_data, data);
    }
  }

  static async $confirmCodeEmail(data: string) {
    if (base_url && endpoints.confirm_code_email) {
      return axios.post<IPostResponse>(base_url + endpoints.confirm_code_email, data);
    }
  }

  static async $getting_key_for_email(data: string) {
    if (base_url && endpoints.get_code_for_email) {
      return axios.post<IPostResponse>(base_url + endpoints.get_code_for_email, data);
    }
  }

  static async $getUserInnData(data: string) {
    if (base_url && endpoints.query_da_data_inn) {
      return axios.post<IUserGeoData>(base_da_data_url + endpoints.query_da_data_inn, data);
    }
  }

  static async $sendSmsCode(data: string) {
    if (base_url && endpoints.send_sms_code) {
      return axios.post<IPostResponse<string>>(base_url + endpoints.send_sms_code, data);
    }
  }

  static async $confirmPhoneProfile(data: string) {
    if (base_url && endpoints.confirm_phone_profile) {
      return axios.post(base_url + endpoints.confirm_phone_profile, data);
    }
  }

  static async $sendEmailCode(data: string) {
    if (base_url && endpoints.confirm_phone_profile) {
      return axios.post(base_url + endpoints.send_email_code, data);
    }
  }

  static async $bindLeaderId(data: string) {
    if (base_url && endpoints.bind_leader_id) {
      return axios.post(base_url + endpoints.bind_leader_id, data);
    }
  }

  static async $callbackLeaderId(data: string) {
    if (base_url && endpoints.callback_leader_id) {
      return axios.post(base_url + endpoints.callback_leader_id, data);
    }
  }

  static async $disconnectWithLeaderId(data: string) {
    if (base_url && endpoints.disconnect_with_leader_id) {
      return axios.post(base_url + endpoints.disconnect_with_leader_id, data);
    }
  }

  static async $telegramBind(data: string) {
    if (base_url && endpoints.telegram_bind) {
      return axios.post(base_url + endpoints.telegram_bind, data);
    }
  }

  static async $confirmTelegramCode(data: string) {
    if (base_url && endpoints.confirm_telegram_profile) {
      return axios.post(base_url + endpoints.confirm_telegram_profile, data);
    }
  }

  static async $esiaBind(data: string): Promise<AxiosResponse<IPostResponse<string>>> {
    if (base_url && endpoints.esia_bind) {
      return axios.post(base_url + endpoints.esia_bind, data);
    }
    return Promise.reject(new Error('Base URL or endpoint for ESIA bind is not defined'));
  }

  static async $esiaUnbind(data: string): Promise<AxiosResponse<IPostResponse<string>>> {
    if (base_url && endpoints.esia_unbind) {
      return axios.post(base_url + endpoints.esia_unbind, data);
    }
    return Promise.reject(new Error('Base URL or endpoint for ESIA unbind is not defined'));
  }

  static async $getEsiaLoginLink(): Promise<AxiosResponse<IPostResponse<string>>> {
    if (base_url && endpoints.get_esia_login_link) {
      return axios.get(base_url + endpoints.get_esia_login_link);
    }
    return Promise.reject(new Error('Base URL or endpoint for ESIA login link is not defined'));
  }

  static async $setPersAgrmnt(data: string): Promise<AxiosResponse<IPostResponse<string>>> {
    if (base_url && endpoints.get_esia_login_link) {
      return axios.post(base_url + endpoints.set_pers_agrmnt, data);
    }
    return Promise.reject(new Error('Base URL or endpoint for setPersAgrmnt is not defined'));
  }

}
